import React from 'react';
import Guacamole from './Guacamole';

const VdiContainer = () => {
  const containerRef = React.createRef();
  const urlParams = new URLSearchParams(window.location.search);
  const location = urlParams.get('location');
  const type = urlParams.get('type');
  const url = urlParams.get('url');
  const username = urlParams.get('username') || '';
  const title = urlParams.get('title') || '';
  const keepAwakeUrl = urlParams.get('keepAwakeUrl');
  const taskName = urlParams.get('taskName') || '';
  
  console.log('Inside container');
  console.log(taskName);

  if (title) {
    document.title = title;
  }

  if (!location && !type && !url) {
    return (
      <></>
    )
  }

  if (keepAwakeUrl) {

    // silently send keep awake every 5 minutes
    setInterval(() => {
      try {
        const request = new XMLHttpRequest();
        request.open('GET', keepAwakeUrl);
        request.send();
      } catch (e) {}
    }, 300000)
  }

  return (
    <>
      <Guacamole
        addToast={() => {}}
        attemptFallback={() => {}}
        colourDepth={32}
        consoleUrl={url}
        containerRef={containerRef}
        cursorType={''}
        location={location}
        me={{}}
        onConnectFail={() => {}}
        onShutdown={() => {}}
        ownLab={true}
        setColourDepth={() => {}}
        setCursor={() => {}}
        title={title}
        type={type}
        username={username}
        taskName={taskName}
      />
    </>
  )  
}

export default VdiContainer;
