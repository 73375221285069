import VdiContainer from './VdiContainer';

function App() {
  return (
    <>
      <VdiContainer />
    </>
  );
}

export default App;
