/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import Guacamole from 'guacamole-common-js';
import PropTypes from 'prop-types';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import BuildIcon from '@material-ui/icons/Build';
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import HighQualityIcon from '@material-ui/icons/HighQuality';
import HelpIcon from '@material-ui/icons/Help';
import MouseIcon from '@material-ui/icons/Mouse';
import ScalingIcon from '@material-ui/icons/AspectRatio';
import {
  Box,
  Button,
  Container,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Draggable from 'react-draggable';
import * as _ from 'lodash';
import fscreen from 'fscreen';
import './VDI.css';
import qwerty from './Keyboards';
// import FdButton from '../../../../Core/FdButton';

const useStyles = makeStyles((theme) => ({
  controlsOuter: {
    display: 'flex',
  },
  controlsInner: {
    zIndex: 1300,
    left: 'calc(50% - 250px)',
    justifyContent: 'center',
    position: 'absolute',
    top: '0px',
    borderRadius: '0px 0px 4px 4px',
    backgroundColor: '#ccc',
    // boxShadow: theme.shadows[6],
  },
  dragHandle: {
    borderLeft: '2px solid #b0b0b0',
    paddingTop: '5px',
  },
  selected: {
    // color: theme.palette.primary.main,
  },
  clipboardDivider: ({ darkTheme }) => ({
    backgroundColor: darkTheme ? '#333' : '#eee',
  }),
  clipboardDrawer: ({ darkTheme }) => ({
    width: '320px',
    background: darkTheme ? 'rgba(0, 0, 0, 0.90)' : '#333',
    padding: 8,// theme.spacing(2),
    paddingBottom: 4,//theme.spacing(1),
  }),
  clipboardTextfield: ({ darkTheme }) => ({
    backgroundColor: darkTheme ? '#000' : '#fff',
    border: '1px solid #595959',
    borderRadius: '4px',
    minHeight: '207px',
    color: darkTheme ? '#fff' : '#000',
  }),
  clipboardTextfieldInput: {
    minHeight: '207px',
    fontWeight: 300,
    maxHeight: 'calc(100vh - 186px)',
    overflowY: 'auto !important',
  },
  clipboardTitle: ({ darkTheme }) => ({
    flexGrow: 1,
    textShadow: darkTheme ? '0 0 8px #000' : '',
    color: darkTheme ? '#eee' : '#fff',
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '26px',
  }),
  clipboardDescription: ({ darkTheme }) => ({
    color: darkTheme ? '#eee' : '#fff',
    fontWeight: 300,
    textShadow: darkTheme ? '0 0 8px #000' : '',
  }),
  clipboardIcons: ({ darkTheme }) => ({
    color: darkTheme ? '#eee' : '#fff',
  }),
}));
const KEY_DOWN = 1;
const KEY_UP = 0;

const VdiControls = ({
  colourDepth,
  containerRef,
  ctrlAltDelete,
  cursor,
  doFallback,
  extendButton,
  fallbackAvailable,
  fullscreen,
  handleRequestFullscreen,
  leaveFullscreen,
  ownLab,
  scale,
  toggleColourDepth,
  toggleCursor,
  toggleKeyboard,
  toggleScale,
  toggleShowClipboard,
  toggleViewOnly,
  type,
  username,
  viewOnly,
  taskName,
}) => {
  const classes = useStyles();
  const [viewAnchor, setViewAnchor] = useState(null);
  const ref = useRef();
  console.log('Inside Lab Controls');
  console.log(taskName);

  return (
    <>
      <Draggable handle="#handleDrag" bounds="parent">
        <Box display="flex" className={classes.controlsInner} ref={ref}>
          {username && (
            <MenuItem>
              <b>{username}</b>
            </MenuItem>
          )}
          {taskName && (
            <MenuItem>
              <b>{taskName}</b>
            </MenuItem>
          )}
          <MenuItem
            onClick={(e) => {
              setViewAnchor(e.currentTarget);
              e.currentTarget.blur();
            }}
            dense
            aria-haspopup="true"
            aria-controls="view-menu"
          >
            {'View '}
            <ExpandMoreIcon />
          </MenuItem>
          <MenuItem onClick={() => toggleKeyboard()} dense>
            On-screen Keyboard
          </MenuItem>
          {type === 'vnc' && (
            <MenuItem onClick={() => toggleShowClipboard()} dense>
              Clipboard
            </MenuItem>
          )}
          <MenuItem onClick={() => ctrlAltDelete()} dense>
            Ctrl-Alt-Del
          </MenuItem>
          {!ownLab && (
            <MenuItem
              onClick={() => toggleViewOnly()}
              className={viewOnly ? classes.selected : ''}
              dense
            >
              View Only
            </MenuItem>
          )}
          {extendButton}
          <div id="handleDrag" className={classes.dragHandle}>
            <DragIndicatorIcon />
          </div>
        </Box>
      </Draggable>
      <Menu
        anchorEl={viewAnchor}
        keepMounted
        open={Boolean(viewAnchor)}
        onClose={() => setViewAnchor(null)}
        id="view-menu"
        container={() => containerRef.current}
      >
        <MenuItem
          onClick={() => {
            setViewAnchor(null);
            if (fullscreen) {
              leaveFullscreen();
            } else {
              handleRequestFullscreen();
            }
          }}
        >
          <ListItemIcon>
            <FullscreenIcon color={fullscreen ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="Fullscreen" />
        </MenuItem>
        <MenuItem onClick={toggleCursor}>
          <ListItemIcon>
            <MouseIcon color={cursor ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="Toggle Cursor" />
        </MenuItem>
        <MenuItem onClick={toggleScale}>
          <ListItemIcon>
            <ScalingIcon color={scale ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText primary="Scaling" />
        </MenuItem>
        <MenuItem onClick={toggleColourDepth}>
          <ListItemIcon>
            <HighQualityIcon color={colourDepth === 8 ? 'inherit' : 'primary'} />
          </ListItemIcon>
          <ListItemText primary={colourDepth === 8 ? 'Increase Quality' : 'Decrease Quality'} />
        </MenuItem>
        {fallbackAvailable && (
          <MenuItem
            onClick={() => {
              setViewAnchor(null);
              doFallback();
            }}
          >
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary="Fallback Console" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
VdiControls.defaultProps = {
  username: null,
  taskName: null,
};
VdiControls.propTypes = {
  colourDepth: PropTypes.number.isRequired,
  containerRef: PropTypes.object.isRequired,
  ctrlAltDelete: PropTypes.func.isRequired,
  cursor: PropTypes.bool.isRequired,
  doFallback: PropTypes.func.isRequired,
  extendButton: PropTypes.element.isRequired,
  fallbackAvailable: PropTypes.bool.isRequired,
  fullscreen: PropTypes.bool.isRequired,
  handleRequestFullscreen: PropTypes.func.isRequired,
  leaveFullscreen: PropTypes.func.isRequired,
  ownLab: PropTypes.bool.isRequired,
  scale: PropTypes.bool.isRequired,
  toggleColourDepth: PropTypes.func.isRequired,
  toggleCursor: PropTypes.func.isRequired,
  toggleKeyboard: PropTypes.func.isRequired,
  toggleScale: PropTypes.func.isRequired,
  toggleShowClipboard: PropTypes.func.isRequired,
  toggleViewOnly: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  username: PropTypes.string,
  taskName: PropTypes.string,
  viewOnly: PropTypes.bool.isRequired,
};

const Clipboard = ({ showClipboard, toggleShowClipboard, guac }) => {
  const [clipboard, setClipboard] = useState('');
  const [darkTheme, setDarkTheme] = useState(true);
  const [helpOpen, setHelpOpen] = useState(false);
  const classes = useStyles({ darkTheme });
  useEffect(() => {
    if (guac) {
      guac.onclipboard = (stream, mimetype) => {
        if (/^text\//.exec(mimetype)) {
          const reader = new Guacamole.StringReader(stream);

          // Assemble received data into a single string
          let data = '';
          reader.ontext = function textReceived(text) {
            data += text;
          };

          // Set clipboard contents once stream is finished
          reader.onend = function textComplete() {
            setClipboard(data);
          };
        }
      };
      return () => {
        guac.onclipboard = null;
      };
    }
    return () => {};
  }, [guac]);
  const sendClipboard = ({ target: { value } }) => {
    setClipboard(value);
    const stream = guac.createClipboardStream('text/plain');
    const writer = new Guacamole.StringWriter(stream);
    writer.sendText(value);
    writer.sendEnd();
  };
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={showClipboard}
      classes={{ paper: classes.clipboardDrawer }}
    >
      <Box display="flex">
        <Typography variant="h3" className={classes.clipboardTitle}>
          Clipboard
        </Typography>
        <IconButton size="small" className={classes.clipboardIcons} onClick={toggleShowClipboard}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Divider className={classes.clipboardDivider} />
      <Box m={1} />
      <Box display="flex" alignItems="center">
        <Typography variant="caption" className={classes.clipboardDescription}>
          VM clipboard
        </Typography>
        <Box flexGrow={1} />
        <IconButton
          size="small"
          className={classes.clipboardIcons}
          onClick={() => setHelpOpen(true)}
        >
          <HelpIcon />
        </IconButton>
      </Box>
      <TextField
        id="clipboard-field"
        multiline
        fullWidth
        placeholder="Copied text will appear here."
        InputProps={{
          classes: {
            root: classes.clipboardTextfield,
            input: classes.clipboardTextfieldInput,
          },
        }}
        value={clipboard}
        onChange={sendClipboard}
        variant="outlined"
      />
      <Box flexGrow={1} />
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={() => setDarkTheme((cur) => !cur)} className={classes.clipboardIcons}>
          <Brightness4Icon />
        </IconButton>
      </Box>
      <Dialog
        open={helpOpen}
        onClose={() => setHelpOpen(false)}
        aria-labelledby="clipboard-help-title"
        aria-describedby="clipboard-help-text"
      >
        <DialogTitle id="clipboard-help-title">VM Clipboard</DialogTitle>
        <DialogContent>
          <DialogContentText id="clipboard-help-text">
            To copy text from within the VDI, copy the text within the VM and the clipboard will be
            filled with the copied text.
            <br />
            To paste external text within VDI, paste text into Clipboard then paste in location
            within VDI.
            <br />
            To use copied text outside this VDI, copy text again from this Clipboard then paste it
            in external location.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <FdButton variant="secondary" onClick={() => setHelpOpen(false)}>
            Close
          </FdButton> */}
        </DialogActions>
      </Dialog>
    </Drawer>
  );
};
Clipboard.defaultProps = {
  guac: null,
};
Clipboard.propTypes = {
  showClipboard: PropTypes.bool.isRequired,
  guac: PropTypes.instanceOf(Guacamole.Client),
  toggleShowClipboard: PropTypes.func.isRequired,
};

const VDI = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteOnConnect: [],
      disconnecting: false,
      errored: null,
      fallback: props.type === 'fallback',
      fullscreen: false,
      guac: null,
      heldKeys: [],
      loaded: false,
      osk: null,
      oskVisible: false,
      remoteHeight: 0,
      remoteWidth: 0,
      scale: true,
      scaling: 1,
      showClipboard: false,
      viewOnly: !props.ownLab,
    };
    this.displayRef = React.createRef();
  }

  componentDidMount() {
    const leave = () => this.leaveFullscreen();
    const enter = () => setTimeout(this.enterFullscreen, 100);
    window.addEventListener('blur', this.handleBlur);
    window.addEventListener('resize', () => this.rescale());
    fscreen.onfullscreenchange = function onfullscreenchange(_event) {
      if (!fscreen.fullscreenElement) {
        leave();
      } else {
        enter();
      }
    };
    this.setupGuac(this.displayRef.current);
  }

  componentDidUpdate(prevProps, prevState) {
    const { colourDepth, cursorType, location } = this.props;
    const { fallback } = this.state;
    if (
      prevProps.colourDepth !== colourDepth ||
      prevProps.cursorType !== cursorType ||
      prevProps.location !== location ||
      prevState.fallback !== fallback
    ) {
      this.reconnect();
    }
  }

  toggleScale = () => {
    this.setState((state) => ({ scale: !state.scale }), this.rescale);
  };

  handleBlur = () => {
    const { heldKeys, guac } = this.state;
    if (guac) {
      const sendRelease = this.handleKey(guac, KEY_UP);
      heldKeys.forEach(sendRelease);
    }
  };

  componentWillUnmount = () => {
    const { guac } = this.state;
    if (guac) {
      guac.disconnect();
    }
    window.removeEventListener('blur', this.handleBlur);
  };

  transformMouse = (event) => {
    const { scaling } = this.state;
    return { ...event, x: event.x / scaling, y: event.y / scaling };
  };

  handleKey = (guac, pressed) => (keysym) => {
    const { viewOnly } = this.state;
    // username is null if it is your own lab
    if (viewOnly) return true;
    if (document.activeElement.tagName === 'BODY') {
      guac.sendKeyEvent(pressed, keysym);
      if (pressed === KEY_DOWN) {
        this.setState(({ heldKeys }) => ({ heldKeys: _.uniq([...heldKeys, keysym]) }));
      } else {
        this.setState(({ heldKeys }) => ({ heldKeys: heldKeys.filter((key) => key !== keysym) }));
      }
      return null;
    }
    return true;
  };

  setupGuac = (display) => {
    const { onShutdown: onShutdownProp, onConnectFail: onConnectFailProp } = this.props;
    const { fallback } = this.state;
    const onShutdown = () => {
      this.setState({ disconnecting: true });
      onShutdownProp();
    };
    const onConnectFail = () => {
      const { attemptFallback, addToast } = this.props;
      if (!fallback) {
        addToast({
          text: 'Unable to connect directly to VM, falling back to hypervisor level VDI',
          type: 'error',
        });
        this.setState({ fallback: true });
        attemptFallback();
      } else {
        onConnectFailProp();
        this.setState({ errored: 'Check your VM is running and reload' });
      }
    };
    const onStateChange = (state) => this.stateChange(state);
    const reconnect = () => this.reconnect();

    // Instantiate client, using a websocket for communications.

    const { consoleUrl } = this.props;
    const guac = new Guacamole.Client(new Guacamole.WebSocketTunnel(consoleUrl));
    const connect = () => {
      // Connect
      const { type, location, colourDepth, cursorType: cursorTypeProp } = this.props;
      // Currently guacamole doesn't support the combination of local cursor, 8 bit colour depth and fallback VDI
      const requiresRemoteCursor = colourDepth === 8 && type === 'fallback';
      const cursorType = requiresRemoteCursor ? 'remote' : cursorTypeProp;
      guac.connect(
        encodeURI(
          JSON.stringify({
            type,
            location,
            colourDepth,
            cursorType,
            fallback: true,
          }),
        ),
      );
    };
    // Hide the mouse
    guac.getDisplay().getElement().className = 'fifthdomain-vdi-screen';

    // Error handler
    guac.onerror = function guacOnError(error) {
      console.log('error')
      console.log(error)
      switch (error.code) {
        case 515: // VNC shutdown or Tiger packet loss
          if (!fallback) {
            reconnect();
          } else {
            // eslint-disable-next-line no-param-reassign
            display.style.filter = 'blur(2px) sepia(100%)';
            onShutdown();
          }
          break;
        case 516: // No tunnel
          reconnect();
          break;
        case 519: // Couldn't connect
          onConnectFail();
          break;
        default:
          // eslint-disable-next-line no-console
          console.log(error.code);
      }
    };

    connect();

    guac.onstatechange = onStateChange;

    // Disconnect on close
    window.onunload = function onWindowClose() {
      guac.disconnect();
    };

    // Mouse
    const mouse = new Guacamole.Mouse(guac.getDisplay().getElement());
    const mouseAction = (mouseState) => {
      const { viewOnly } = this.state;
      if (viewOnly) return;
      document.activeElement.blur();
      display.focus();
      guac.sendMouseState(this.transformMouse(mouseState));
    };
    mouse.onmousedown = mouseAction;
    mouse.onmouseup = mouseAction;
    mouse.onmousemove = mouseAction;
    mouse.onmousedown = mouseAction;
    mouse.onmouseup = mouseAction;
    mouse.onmousemove = mouseAction;

    // Keyboard
    const keyboard = new Guacamole.Keyboard(document);

    display.focus(); // So they can type immediately

    keyboard.onkeydown = this.handleKey(guac, KEY_DOWN);

    keyboard.onkeyup = this.handleKey(guac, KEY_UP);

    // On screen keyboard
    const osk = new Guacamole.OnScreenKeyboard(qwerty);
    osk.onkeydown = function oskKeyDown(keysym) {
      guac.sendKeyEvent(1, keysym);
    };
    osk.onkeyup = keyboard.onkeyup;
    osk.getElement().style.display = 'none';

    // Add client to display div
    display.appendChild(guac.getDisplay().getElement());
    display.appendChild(osk.getElement());

    // Scaling
    guac.getDisplay().onresize = this.rescale;

    // Fallback
    setTimeout(() => {
      const { attemptFallback } = this.props;
      const { loaded } = this.state;
      if (!loaded && !fallback) {
        // eslint-disable-next-line no-console
        console.log('Falling back to hypervisor-based VNC');
        this.setState({ fallback: true });
        attemptFallback();
      }
    }, 10000);

    this.setState({ guac, osk });
  };

  stateChange = (state) => {
    const display = this.displayRef.current;
    switch (state) {
      case 0: // Idle
        return;
      case 1: // Connecting
        this.setState({ loaded: false });
        return;
      case 2: // Waiting
        this.setState({ loaded: false });
        return;
      case 3: {
        // Connected
        const { deleteOnConnect } = this.state;
        display.style.filter = ''; // Remove any filter (i.e sepia)
        deleteOnConnect.forEach((child) => {
          display.removeChild(child);
        });
        this.setState({ loaded: true, deleteOnConnect: [], errored: null });
        return;
      }
      case 4: // Disconnecting
        this.setState({ loaded: true });
        return;
      case 5: {
        // Disconnected
        const oldDisplay = this.displayRef.current;
        const { disconnecting, errored } = this.state;
        const children = [];
        // .forEach doesn't seem to work here
        if (oldDisplay && oldDisplay.children) {
          for (let i = 0; i < oldDisplay.children.length; i += 1) {
            children.push(oldDisplay.children[i]);
          }
        }
        this.setState({ loaded: true, deleteOnConnect: children });
        if (!disconnecting && !errored) {
          this.reconnect();
        }
        return;
      }
      default:
        // Should be unreachable
        // eslint-disable-next-line no-console
        console.log(`Unknown VDI state ${state}`);
    }
  };

  reconnect = () => {
    const { guac } = this.state;
    if (guac) {
      const display = this.displayRef.current;
      if (!display) return; // Has become unmounted
      const children = [];
      // .forEach doesn't seem to work here
      for (let i = 0; i < display.children.length; i += 1) {
        children.push(display.children[i]);
      }
      this.setState({ deleteOnConnect: children });
      guac.onstatechange = undefined;
      guac.disconnect();
    }
    this.setupGuac(this.displayRef.current);
  };

  enterFullscreen = () => {
    const { guac } = this.state;
    const guacDisplay = guac.getDisplay();
    guacDisplay.scale(1);
    const height = guacDisplay.getHeight();
    const width = guacDisplay.getWidth();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const heightRatio = windowHeight / height;
    const widthRatio = windowWidth / width;
    guacDisplay.scale(widthRatio > heightRatio ? heightRatio : widthRatio);
    guacDisplay.getElement().className = 'fifthdomain-vdi-screen fifthdomain-vdi-screen-fullscreen';
  };

  rescale = (w, h) => {
    const { guac, osk, oskVisible, remoteWidth, remoteHeight, scale } = this.state;
    const newWidth = w || remoteWidth;
    const newHeight = h || remoteHeight;
    const guacDisplay = guac.getDisplay();
    guacDisplay.scale(1);
    const windowHeight = oskVisible ? window.innerHeight * 0.66 : window.innerHeight;
    const windowWidth = window.innerWidth;
    const heightRatio = windowHeight / newHeight;
    const widthRatio = windowWidth / newWidth;
    const preScaling = widthRatio > heightRatio ? heightRatio : widthRatio;
    const scaling = scale ? preScaling : Math.min(1, preScaling);
    const keyboardMaxWidth = Math.min(window.innerWidth, newWidth * scaling);
    const keyboardMaxHeight = Math.min(window.innerHeight * 0.33, newHeight * scaling);
    const oskWidth = Math.min(keyboardMaxWidth, keyboardMaxHeight * 3.2);
    guacDisplay.scale(scaling);
    osk.resize(oskWidth);
    this.setState({ remoteWidth: newWidth, remoteHeight: newHeight, scaling });
  };

  handleRequestFullscreen = () => {
    const { addToast, containerRef } = this.props;
    const display = containerRef.current;
    const fullscreenCall =
      display.requestFullscreen ||
      display.webkitRequestFullScreen ||
      display.mozRequestFullScreen ||
      display.msRequestFullScreen;
    if (fullscreenCall) {
      window.scrollTo(0, 0); // Thank Andy for this wonderful fix
      fullscreenCall.call(display);
      display.focus(); // So they can type immediately
      this.setState({ fullscreen: true });
    } else {
      addToast({
        type: 'error',
        text: "Your browser doesn't support fullscreen",
      });
    }
  };

  leaveFullscreen = () => {
    const { guac } = this.state;
    document.exitFullscreen().catch(() => {}); // Exit fullscreen is called twice so safe to catch
    const guacDisplay = guac.getDisplay();
    guacDisplay.getElement().className = 'fifthdomain-vdi-screen';
    this.rescale();
    this.setState({ fullscreen: false });
  };

  toggleKeyboard = () => {
    const { oskVisible, osk } = this.state;
    this.setState({ oskVisible: !oskVisible }, this.rescale);
    osk.getElement().style.display = oskVisible ? 'none' : '';
  };

  toggleCursor = () => {
    const { cursorType, setCursor } = this.props;
    if (cursorType === 'remote') {
      setCursor(false);
    } else {
      setCursor(true);
    }
  };

  toggleColourDepth = () => {
    const { colourDepth, setColourDepth } = this.props;
    if (colourDepth === 8) {
      setColourDepth(32);
    } else {
      setColourDepth(8);
    }
  };

  toggleViewOnly = () => {
    this.setState((state) => ({
      viewOnly: !state.viewOnly,
    }));
  };

  toggleShowClipboard = () => {
    this.setState((state) => ({
      showClipboard: !state.showClipboard,
    }));
  };

  ctrlAltDelete = () => {
    const { guac } = this.state;
    if (guac) {
      const ctrl = 0xffe3;
      const alt = 0xffe9;
      const del = 0xffff;
      guac.sendKeyEvent(1, ctrl);
      guac.sendKeyEvent(1, alt);
      guac.sendKeyEvent(1, del);

      // Hold it down for half a second
      setTimeout(() => {
        guac.sendKeyEvent(0, ctrl);
        guac.sendKeyEvent(0, alt);
        guac.sendKeyEvent(0, del);
      }, 500);
    }
  };

  render = () => {
    const { errored, loaded, fallback, fullscreen, scale, viewOnly, showClipboard, guac } =
      this.state;
    const {
      attemptFallback,
      colourDepth,
      containerRef,
      cursorType,
      extendButton,
      me,
      ownLab,
      stopTime,
      title,
      type,
      username,
      taskName
    } = this.props;
    console.log('Inside guac render');
    console.log(taskName);

    const vdiClass = viewOnly ? 'fifthdomain-vdi' : 'fifthdomain-vdi fifthdomain-vdi-interactive';
    const doFallback = () => {
      this.setState({ fallback: true });
      attemptFallback();
    };
    const orgId = _.get(me, ['data', 'org', 'id']);
    // Hack for ANU...
    const fallbackAvailable =
      !fallback &&
      type === 'vnc' &&
      (orgId === 'Org:99' || orgId === '99' || orgId === 'Org:101' || orgId === '101');
    return (
      <div ref={containerRef}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{
            backgroundColor: '#121212',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <>
            {loaded || errored || (
              <Container style={{ color: '#fff', textAlign: 'center' }}>
                <Typography variant="h4" style={{ color: '#fff' }}>
                  {`Connecting to ${title}`}
                </Typography>
                <Box m={4} />
                <CircularProgress color="#fff" />
              </Container>
            )}
            {errored && (
              <Container style={{ color: '#fff', textAlign: 'center' }}>
                <Typography variant="h4" style={{ color: '#fff' }}>
                  Connection Error
                </Typography>
                <Typography variant="subtitle1" style={{ color: '#fff' }}>
                  {errored}
                </Typography>
                <Box m={4} />
                <Button variant="contained" onClick={() => window.location.reload()}>
                  Reload
                </Button>
              </Container>
            )}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              style={{
                width: '100vw',
                overflow: 'hidden',
              }}
            >
              <div className={vdiClass} ref={this.displayRef} role="application" />
            </Box>
          </>
        </Box>
        <Clipboard
          showClipboard={showClipboard}
          guac={guac}
          toggleShowClipboard={this.toggleShowClipboard}
        />
        <VdiControls
          colourDepth={colourDepth}
          containerRef={containerRef}
          ctrlAltDelete={this.ctrlAltDelete}
          cursor={cursorType === 'remote'}
          doFallback={doFallback}
          errored={errored}
          extendButton={extendButton}
          fallbackAvailable={fallbackAvailable}
          fullscreen={fullscreen}
          handleRequestFullscreen={this.handleRequestFullscreen}
          leaveFullscreen={this.leaveFullscreen}
          loaded={loaded}
          ownLab={ownLab}
          scale={scale}
          showClipboard={showClipboard}
          stopTime={stopTime}
          toggleColourDepth={this.toggleColourDepth}
          toggleCursor={this.toggleCursor}
          toggleKeyboard={this.toggleKeyboard}
          toggleScale={this.toggleScale}
          toggleShowClipboard={this.toggleShowClipboard}
          toggleViewOnly={this.toggleViewOnly}
          type={type}
          username={username}
          taskName={taskName}
          viewOnly={viewOnly}
        />
      </div>
    );
  };
};

VDI.propTypes = {
  addToast: PropTypes.func.isRequired,
  attemptFallback: PropTypes.func.isRequired,
  colourDepth: PropTypes.number,
  consoleUrl: PropTypes.string.isRequired,
  containerRef: PropTypes.object.isRequired,
  cursorType: PropTypes.string.isRequired,
  extendButton: PropTypes.element.isRequired,
  location: PropTypes.string.isRequired,
  me: PropTypes.object.isRequired,
  onConnectFail: PropTypes.func.isRequired,
  onShutdown: PropTypes.func.isRequired,
  ownLab: PropTypes.bool.isRequired,
  setColourDepth: PropTypes.func.isRequired,
  setCursor: PropTypes.func.isRequired,
  stopTime: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  username: PropTypes.string,
  taskName: PropTypes.string,
};

VDI.defaultProps = {
  colourDepth: 24,
  stopTime: null,
  title: 'VM',
  username: null,
  taskName: null,
};

export default VDI;
